<template>
	<img src="/images/common/loading_high_pro.gif" width="250" height="250" />
</template>

<script>
export default {
	props : ['param'],
	data() {
		return {
		};
	},
	methods: {
	},
	computed: {
	}
};
</script>